html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
del,
em,
img,
small,
strong,
b,
u,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
button,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  word-wrap: break-word;
}

html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: inherit;
  font-weight: inherit;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}

a img {
  border: none;
}

article,
aside,
details,
figure,
footer,
header,
menu,
nav,
section,
summary {
  display: block;
}

button,
a {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: "Fonts";
  src: url("./assets/fonts/tt-norms-pro-cufonfonts/TTNormsProRegular.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Fonts";
  src: url("./assets/fonts/tt-norms-pro-cufonfonts/TTNormsProBold.otf");
  font-weight: 600;
}

body {
  background-color: #010314;
  color: #fff;
  font-family: Fonts, sans-serif;
}

.app {
  width: 97%;
  margin: 0 auto;
  overflow: hidden;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-primary {
  padding: 10px 15px;
  border-radius: 100vh;
  border: 1px solid rgb(127, 82, 255);
  box-shadow: inset 0 1px 2px #ffffff80;
  transition: all 0.3s linear;
  &:hover {
    color: #fff;
    background-color: rgb(127, 82, 255);
  }
}

.header {
  padding-top: 10px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 25px;
  }
  &-logo {
    width: 200px;
    @media screen and (max-width: 1000px) {
      width: 170px;
    }
    img {
      width: 100%;
    }
  }
  &-menu {
    display: flex;
    border: 1px solid hsla(0, 0%, 100%, 0.2);
    padding: 15px 30px;
    border-radius: 100vh;
    li {
      &:not(:last-child) {
        margin-right: 50px;
        @media screen and (max-width: 600px) {
          margin-right: 30px;
        }
        @media screen and (max-width: 500px) {
          margin-right: 20px;
        }
      }
      a {
        transition: all 0.2s linear;
        &:hover {
          color: rgb(127, 82, 255);
        }
      }
    }
  }
  &-btn {
    background-color: #fff;
    color: #000;
    padding: 10px 15px;
    border-radius: 100vh;
    transition: all 0.3s linear;
    &:hover {
      background-color: rgb(127, 82, 255);
      color: #fff;
    }
  }
}

.main {
  position: relative;
  margin-bottom: 50px;
  border-radius: 50px;
  min-height: calc(100vh - 150px);
  background: radial-gradient(
    140% 107.13% at 50% 10%,
    transparent 37.41%,
    #63e 69.27%,
    #fff 100%
  );
  display: flex;
  justify-content: center;
  &-img {
    padding-top: 100px;
    width: 400px;
    height: 400px;
    @media screen and (max-width: 1000px) {
      width: 300px;
      height: 300px;
      padding-top: 60px;
    }
    @media screen and (max-width: 6000px) {
      padding-top: 50px;
    }
    img {
      width: 100%;
      animation: rotating 10s linear infinite;
    }
  }
  &-content {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-25%);
    @media screen and (max-width: 500px) {
      transform: translateY(-33%);
    }
    h3 {
      font-size: 50px;
      line-height: 1.2;
      @media screen and (max-width: 1000px) {
        font-size: 40px;
        line-height: 1.1;
      }
    }
  }
  &-footer {
    margin-top: 100px;
  }
  &-social {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    li {
      &:not(:last-child) {
        margin-right: 25px;
      }
      a {
        transition: all 0.2s linear;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.nerdy-pen {
  &__text {
    color: #fff;
    margin: 20px 0 50px;
    font-size: 35px;
    @media screen and (max-width: 1000px) {
      font-size: 35px;
    }
    @media screen and (max-width: 600px) {
      font-size: 30px;
    }
  }
}

.txt-rotate {
  background: rgb(132, 245, 222);
  background: linear-gradient(
    59deg,
    rgba(132, 245, 222, 1) 20%,
    rgba(0, 180, 255, 1) 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
